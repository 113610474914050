import { z } from "zod";
import ModalDialog from "../../../layout/modal-dialog";
import { useForm } from "../../../hooks/useForm";
import { Form } from "../../../layout/form/form";
import { getAuthTokenNoThrow } from "../../../services/auth-header";
import ButtonNeoGen from "../../../layout/button-neogen";
import { Company } from "../../domain/company";
import { NumberField } from "../../../layout/form/number-field";
import { TextAreaField } from "../../../layout/form/text-area-field";
import { User } from "../../../jason-proof-of-concept/users/domain/user";
import { Field } from "../../../layout/form/field";
import { useCreatePaymentMutation } from "../../../payments/hooks/use-create-payment";
import { DateTimeField } from "../../../layout/form/date-field";
import { useAuth } from "../../../auth/use-auth";
import { Payment } from "../../../payments/domain/payment";
import { TextField } from "../../../layout/form/text-field";

const schema = z.object({
    amount: z.number(),
    date: z.date(),
    description: z.string().optional(),
    reference: z.string().optional(),
    notes: z.string().optional(),
});

type Data = z.infer<typeof schema>;

export const CreatePaymentModal = ({
    onClose,
    companyId,
    defaultAmount,
    from,
    fromUser,
    fromClearErc,
    to,
    toUser,
    toClearErc,
    description,
    onPaymentCaptured,
    paymentRequestId,
}: {
    onClose: () => any;
    companyId: number;
    defaultAmount?: number;
    from?: string;
    fromUser?: User;
    fromClearErc?: boolean;
    to?: string;
    toUser?: User;
    toClearErc?: boolean;
    description?: string;
    onPaymentCaptured: (payment: Payment) => void;
    paymentRequestId?: string;
}) => {
    const auth = useAuth();
    const authedUser = auth.expectUser();
    const authToken = getAuthTokenNoThrow() || "no-auth-token";

    const form = useForm({
        schema,
        defaultValues: {
            amount: defaultAmount,
            description,
            notes: "",
            date: new Date(),
        },
    });

    const createPaymentMutation = useCreatePaymentMutation();

    const handleSubmit = async (data: Data) => {
        const payment = await createPaymentMutation.mutateAsync({
            authToken,
            data: {
                createdById: authedUser.id,
                companyId,
                date: data.date,
                amount: data.amount,
                notes: data.notes,
                description: data.description,
                paymentRequestId,
                from,
                fromClearErc,
                fromUserId: fromUser?.id,
                to,
                toClearErc,
                toUserId: toUser?.id,
            },
        });
        onPaymentCaptured(payment);
    };

    return (
        <ModalDialog show title={"Create payment"} close={onClose} showOk={false} showCancel={false} size="sm">
            <p className="text-center mb-6 text-gray-400">Create a payment which has been made.</p>
            <Form onSubmit={form.handleSubmit(handleSubmit as any)} error={createPaymentMutation.error as any}>
                <div className="flex flex-row">
                    <div className="flex-1">
                        <Field label="From">{fromClearErc ? "ClearERC" : from || fromUser?.firstName}</Field>
                    </div>
                    <div className="flex-1">
                        <Field label="To">{toClearErc ? "ClearERC" : to || toUser?.firstName}</Field>
                    </div>
                </div>
                <TextField label="Description" {...form.getFieldProps("description")} />
                <DateTimeField label="Date" dateFormat="yyyy/MM/dd hh:mm" {...form.getFieldProps("date")} />
                <NumberField label="Amount" {...form.getFieldProps("amount")} />
                <TextField label="Payment reference" {...form.getFieldProps("reference")} />
                <TextAreaField label="Notes" {...form.getFieldProps("notes")} />
                <div className="flex justify-end gap-4">
                    <ButtonNeoGen type="outline" disabled={createPaymentMutation.isLoading} onClick={() => onClose()}>
                        Cancel
                    </ButtonNeoGen>
                    <ButtonNeoGen type="submit" disabled={createPaymentMutation.isLoading}>
                        <i className="fa fa-check text-green-500 mr-2" />
                        Create payment
                    </ButtonNeoGen>
                </div>
            </Form>
        </ModalDialog>
    );
};
