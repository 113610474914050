import { z } from "zod";

export const processflowProgressSchema = z.object({
    id: z.number(),
    group: z.number(),
    currentStep: z.number().nullable().optional(),
    currentStage: z.number().nullable().optional(),
    userId: z.string(),
    data: z
        .object({
            completedSteps: z.array(z.any()).optional(),
            entriesByField: z.record(
                z.object({ fieldName: z.string(), fieldValue: z.any(), isHidden: z.boolean().optional() }),
            ),
            currentStage: z.number().nullable().optional(),
            currentStep: z.number().nullable().optional(),
            numberOfRelevantInteractiveEntries: z.number().nullable().optional(),
            numberOfCompletedEntries: z.number().nullable().optional(),
            percentageComplete: z.number().nullable().optional(),
            furthestStageId: z.number().nullable().optional(),
        })
        .nullable()
        .optional(),
    client: z.number().nullable().optional(),
    createdAt: z.date().nullish(),
    updatedAt: z.date().nullish(),
});

export type ProcessflowProgress = z.infer<typeof processflowProgressSchema>;
