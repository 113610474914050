import { z } from "zod";

export const transactionSchema = z.object({
    id: z.string().uuid(),
    createdAt: z.coerce.date(),
    updatedAt: z.coerce.date(),
    createdById: z.string().uuid(),
    companyId: z.number(),
    paymentId: z.string().uuid().nullish(),
    date: z.coerce.date(),
    amount: z.number(),
    toUserId: z.string().uuid().nullish(),
    fromUserId: z.string().uuid().nullish(),
    toClearErc: z.boolean().nullish(),
    fromClearErc: z.boolean().nullish(),
    to: z.string().nullish(),
    from: z.string().nullish(),
    reference: z.string().nullish(),
    notes: z.string().nullish(),
    description: z.string().nullish(),
});

export type Transaction = z.infer<typeof transactionSchema>;
