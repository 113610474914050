import { useCallback, useEffect, useMemo, useState } from "react";
import Loader2 from "../../sections/utilities/Loader2";
import PageDescription from "../../layout/page-description";
import { search as ssSearch } from "ss-search";
import { debounce } from "lodash";
import { getAuthTokenNoThrow } from "../../services/auth-header";
import { useUsers } from "../../jason-proof-of-concept/users/hooks/use-users";
import { useNavigate } from "react-router";
import { useRoleGroups } from "../../role-groups/hooks/use-role-groups";
import { TBody, THead, Table, Td, Th, Tr } from "../../layout/table";
import { useBreakpoint } from "../../hooks/appMedia";
import { Currency } from "../../billing/components";
import SearchField from "../../layout/search-field";
import { usePayments } from "../hooks/use-payments";
import { Payment } from "../domain/payment";

export function PaymentsPage({ darkMode }: { darkMode?: boolean }) {
    const [search, setSearch] = useState("");
    const [debouncedSearch, setDebouncedSearch] = useState("");
    const authToken = getAuthTokenNoThrow() || "no-auth";
    const navigate = useNavigate();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const updateDebouncedSearch = useCallback(
        debounce(
            (term) => {
                setDebouncedSearch(term);
            },
            700,
            { trailing: true, maxWait: 1000 },
        ),
        [],
    );
    useEffect(() => {
        updateDebouncedSearch(search);
    }, [search, updateDebouncedSearch]);

    const paymentsQuery = usePayments({
        authToken,
        filters: { ...(status !== null ? { where: { status: status } } : {}) },
    });
    const payments = useMemo(() => paymentsQuery.data || [], [paymentsQuery.data]);
    const filteredPayments = useMemo(
        () =>
            debouncedSearch
                ? (ssSearch(
                      payments,
                      [
                          "from",
                          "fromUser.firstName",
                          "fromUser.lastName",
                          "fromUser.email",
                          "to",
                          "toUser.firstName",
                          "toUser.lastName",
                          "toUser.email",
                          "company.name",
                          "description",
                      ],
                      debouncedSearch,
                  ) as Payment[])
                : payments,
        [payments, debouncedSearch],
    );

    const usersQuery = useUsers({ authToken });
    const users = useMemo(() => usersQuery.data || [], [usersQuery.data]);
    const [searchOpen, setSearchOpen] = useState(false);
    const roleGroupsQuery = useRoleGroups({ authToken });
    const roleGroups = useMemo(() => roleGroupsQuery.data || [], [roleGroupsQuery.data]);
    const breakpoints = useBreakpoint();
    const isMobile = breakpoints.breakpoint === "mobile";
    const isTablet = breakpoints.breakpoint === "tablet";

    return (
        <>
            <PageDescription title="Payments">
                <div className="flex flex-row gap-4 items-center">
                    <div className="text-left">
                        Search
                        <SearchField search={search} setSearch={setSearch} placeholder="Search payments..." />
                    </div>
                </div>
            </PageDescription>
            <div>
                {paymentsQuery.isLoading ? (
                    <div className={"p-5 text-center"}>
                        <Loader2 />
                    </div>
                ) : (
                    <>
                        <Table>
                            <THead>
                                <Tr>
                                    <Th>Description</Th>
                                    <Th>Company</Th>
                                    <Td>Date</Td>
                                    <Th>From</Th>
                                    <Th>To</Th>
                                    <Th>Amount</Th>
                                </Tr>
                            </THead>
                            <TBody>
                                {filteredPayments.length === 0 && (
                                    <Tr>
                                        <Td colSpan={8} style={{ textAlign: "center" }}>
                                            No payments
                                        </Td>
                                    </Tr>
                                )}
                                {filteredPayments.length > 0 &&
                                    filteredPayments.map((payment) => {
                                        return (
                                            <Tr key={payment.id}>
                                                <Td>{payment.description || "-"}</Td>
                                                <Td>{payment.company?.name}</Td>
                                                <Td>{payment.date.toDateString() || "-"}</Td>
                                                <Td>
                                                    {payment.fromClearErc
                                                        ? "ClearERC"
                                                        : payment.fromUser
                                                        ? [payment.fromUser.firstName, payment.fromUser.lastName].join(
                                                              " ",
                                                          )
                                                        : payment.from}
                                                </Td>
                                                <Td>
                                                    {payment.toClearErc
                                                        ? "ClearERC"
                                                        : payment.toUser
                                                        ? [payment.toUser.firstName, payment.toUser.lastName].join(" ")
                                                        : payment.to}
                                                </Td>
                                                <Td>
                                                    <Currency amount={payment.amount} />
                                                </Td>
                                            </Tr>
                                        );
                                    })}
                            </TBody>
                        </Table>
                    </>
                )}
            </div>
        </>
    );
}
