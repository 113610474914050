import { useCallback, useEffect, useMemo, useState } from "react";
import Loader2 from "../../sections/utilities/Loader2";
import PageDescription from "../../layout/page-description";
import { search as ssSearch } from "ss-search";
import { debounce, startCase } from "lodash";
import { getAuthTokenNoThrow } from "../../services/auth-header";
import { useUsers } from "../../jason-proof-of-concept/users/hooks/use-users";
import { useNavigate } from "react-router";
import { useRoleGroups } from "../../role-groups/hooks/use-role-groups";
import { TBody, THead, Table, Td, Th, Tr } from "../../layout/table";
import { timeAgo } from "../../sections/users/cards/calls-list";
import { useBreakpoint } from "../../hooks/appMedia";
import { usePaymentRequests } from "../hooks/use-payment-requests";
import { PaymentRequest, PaymentRequestStatus } from "../domain/payment-request";
import { Currency } from "../../billing/components";
import OptionsDropDown from "../../layout/options-dropdown";
import SwalNeogenFire from "../../layout/swal-neogen";
import { getDarkMode } from "../../sections/admin/magic-links/magic-links";
import { User } from "../../jason-proof-of-concept/users/domain/user";
import { CreatePaymentModal } from "../../companies/components/modals/create-payment-modal";
import { useUpdatePaymentRequest } from "../hooks/use-update-payment-request";
import { Select } from "../../layout/form/select-input";
import SearchField from "../../layout/search-field";

export function PaymentRequestsPage({ darkMode }: { darkMode?: boolean }) {
    const [search, setSearch] = useState("");
    const [debouncedSearch, setDebouncedSearch] = useState("");
    const authToken = getAuthTokenNoThrow() || "no-auth";
    const [status, setStatus] = useState<PaymentRequestStatus | null>(PaymentRequestStatus.Pending);
    const navigate = useNavigate();
    const [showCapturePaymentModal, setShowCapturePaymentModal] = useState<
        | {
              companyId: number;
              amount: number;
              reason: string;
              from?: string;
              fromClearErc?: boolean;
              fromUser?: User;
              to?: string;
              toClearErc?: boolean;
              toUser?: User;
              paymentRequestId?: string;
          }
        | undefined
    >();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const updateDebouncedSearch = useCallback(
        debounce(
            (term) => {
                setDebouncedSearch(term);
            },
            700,
            { trailing: true, maxWait: 1000 },
        ),
        [],
    );
    useEffect(() => {
        updateDebouncedSearch(search);
    }, [search, updateDebouncedSearch]);

    const paymentRequestsQuery = usePaymentRequests({
        authToken,
        filters: { ...(status !== null ? { where: { status: status } } : {}) },
    });
    const paymentRequests = useMemo(() => paymentRequestsQuery.data || [], [paymentRequestsQuery.data]);
    const filteredPaymentRequests = useMemo(
        () =>
            debouncedSearch
                ? (ssSearch(
                      paymentRequests,
                      [
                          "from",
                          "fromUser.firstName",
                          "fromUser.lastName",
                          "fromUser.email",
                          "to",
                          "toUser.firstName",
                          "toUser.lastName",
                          "toUser.email",
                          "company.name",
                          "description",
                      ],
                      debouncedSearch,
                  ) as PaymentRequest[])
                : paymentRequests,
        [paymentRequests, debouncedSearch],
    );

    const usersQuery = useUsers({ authToken });
    const users = useMemo(() => usersQuery.data || [], [usersQuery.data]);
    const [searchOpen, setSearchOpen] = useState(false);
    const roleGroupsQuery = useRoleGroups({ authToken });
    const roleGroups = useMemo(() => roleGroupsQuery.data || [], [roleGroupsQuery.data]);
    const breakpoints = useBreakpoint();
    const isMobile = breakpoints.breakpoint === "mobile";
    const isTablet = breakpoints.breakpoint === "tablet";

    const updatePaymentRequestMutation = useUpdatePaymentRequest();

    return (
        <>
            {showCapturePaymentModal && (
                <CreatePaymentModal
                    companyId={showCapturePaymentModal.companyId}
                    description={showCapturePaymentModal.reason}
                    from={showCapturePaymentModal.from}
                    fromClearErc={showCapturePaymentModal.fromClearErc}
                    fromUser={showCapturePaymentModal.fromUser}
                    to={showCapturePaymentModal.to}
                    toUser={showCapturePaymentModal.toUser}
                    toClearErc={showCapturePaymentModal.toClearErc}
                    onClose={() => {
                        setShowCapturePaymentModal(undefined);
                    }}
                    defaultAmount={showCapturePaymentModal.amount || 0}
                    paymentRequestId={showCapturePaymentModal.paymentRequestId}
                    onPaymentCaptured={() => {
                        setShowCapturePaymentModal(undefined);
                        paymentRequestsQuery.refetch();
                    }}
                />
            )}
            <PageDescription title="Payment requests">
                <div className="flex flex-row gap-4 items-center">
                    <div className="w-[190px] text-left">
                        Status
                        <Select
                            isClearable
                            value={status}
                            onChange={(v) => setStatus(v === "all" ? null : (v as any))}
                            options={[
                                { label: "Pending", value: PaymentRequestStatus.Pending },
                                { label: "Complete", value: PaymentRequestStatus.Complete },
                                { label: "Cancelled", value: PaymentRequestStatus.Cancelled },
                                { label: "All", value: "all" },
                            ]}
                        />
                    </div>
                    <div className="text-left">
                        Search
                        <SearchField search={search} setSearch={setSearch} placeholder="Search payment requests..." />
                    </div>
                </div>
            </PageDescription>
            <div>
                {paymentRequestsQuery.isLoading ? (
                    <div className={"p-5 text-center"}>
                        <Loader2 />
                    </div>
                ) : (
                    <>
                        <Table>
                            <THead>
                                <Tr>
                                    <Th>Description</Th>
                                    <Th>Status</Th>
                                    <Th>Requested payment date</Th>
                                    <Th>Company</Th>
                                    <Th>From</Th>
                                    <Th>To</Th>
                                    <Th>Amount</Th>
                                    <Th />
                                </Tr>
                            </THead>
                            <TBody>
                                {filteredPaymentRequests.length === 0 && (
                                    <Tr>
                                        <Td colSpan={8} style={{ textAlign: "center" }}>
                                            No {status ? status : ""} payment requests
                                        </Td>
                                    </Tr>
                                )}
                                {filteredPaymentRequests.length > 0 &&
                                    filteredPaymentRequests.map((pr) => {
                                        return (
                                            <Tr key={pr.id}>
                                                <Td>{pr.description || "-"}</Td>
                                                <Td>
                                                    <div className="flex flex-col">
                                                        {pr.status ? startCase(pr.status) : "-"}
                                                        {pr.status === PaymentRequestStatus.Complete && (
                                                            <span style={{ color: "gray" }}>
                                                                {pr.paymentDate?.toDateString()}
                                                            </span>
                                                        )}
                                                    </div>
                                                </Td>
                                                <Td style={{ paddingTop: 0, paddingBottom: 0 }}>
                                                    <div className="flex flex-col">
                                                        {pr.requestedPaymentDate?.toDateString()}
                                                        <div style={{ color: "gray" }}>
                                                            {pr.requestedPaymentDate
                                                                ? timeAgo(pr.requestedPaymentDate).toLocaleString()
                                                                : null}
                                                        </div>
                                                    </div>
                                                </Td>
                                                <Td>{pr.company?.name}</Td>
                                                <Td>
                                                    {pr.fromClearErc
                                                        ? "ClearERC"
                                                        : pr.fromUser
                                                        ? [pr.fromUser.firstName, pr.fromUser.lastName].join(" ")
                                                        : pr.from}
                                                </Td>
                                                <Td>
                                                    {pr.toClearErc
                                                        ? "ClearERC"
                                                        : pr.toUser
                                                        ? [pr.toUser.firstName, pr.toUser.lastName].join(" ")
                                                        : pr.to}
                                                </Td>
                                                <Td>
                                                    <Currency amount={pr.amount} />
                                                </Td>
                                                <Td>
                                                    <OptionsDropDown
                                                        options={[
                                                            {
                                                                label: "Create payment",
                                                                disabled: pr.status !== PaymentRequestStatus.Pending,
                                                                onClick: () => {
                                                                    setShowCapturePaymentModal({
                                                                        companyId: pr.companyId,
                                                                        reason: pr.description || "",
                                                                        toUser: pr.toUser || undefined,
                                                                        fromUser: pr.fromUser || undefined,
                                                                        toClearErc: pr.toClearErc || false,
                                                                        fromClearErc: pr.fromClearErc || false,
                                                                        amount: pr.amount,
                                                                        paymentRequestId: pr.id,
                                                                    });
                                                                },
                                                            },
                                                            {
                                                                label: "Cancel",
                                                                disabled: pr.status !== PaymentRequestStatus.Pending,
                                                                onClick: () => {
                                                                    SwalNeogenFire({
                                                                        darkMode: getDarkMode(),
                                                                        title: "Cancel payment request?",
                                                                        text: "Are you sure you want to cancel this payment request?",
                                                                        icon: "info",
                                                                        showCancelButton: true,
                                                                        confirmButtonText: "Yes",
                                                                        cancelButtonText: "No",
                                                                        showLoaderOnConfirm: true,
                                                                    }).then(async (result) => {
                                                                        if (result.isConfirmed) {
                                                                            await updatePaymentRequestMutation.mutateAsync(
                                                                                {
                                                                                    authToken,
                                                                                    id: pr.id,
                                                                                    data: {
                                                                                        status: PaymentRequestStatus.Cancelled,
                                                                                    },
                                                                                },
                                                                            );
                                                                            paymentRequestsQuery.refetch();
                                                                        }
                                                                    });
                                                                },
                                                            },
                                                        ]}
                                                    />
                                                </Td>
                                            </Tr>
                                        );
                                    })}
                            </TBody>
                        </Table>
                    </>
                )}
            </div>
        </>
    );
}
