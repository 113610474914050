import { useCallback, useEffect, useMemo, useState } from "react";
import Loader2 from "../../sections/utilities/Loader2";
import PageDescription from "../../layout/page-description";
import { search as ssSearch } from "ss-search";
import { debounce, startCase } from "lodash";
import { NewCompanyModal } from "../components/modals/new-company-modal";
import { useCompanies } from "../hooks/use-companies";
import { getAuthTokenNoThrow } from "../../services/auth-header";
import { Company } from "../domain/company";
import { useUsers } from "../../jason-proof-of-concept/users/hooks/use-users";
import { useNavigate } from "react-router";
import { useRoleGroups } from "../../role-groups/hooks/use-role-groups";
import UserCompanyPicker from "../../layout/user-company-picker";
import { TBody, THead, Table, Td, Th, Tr } from "../../layout/table";
import { timeAgo } from "../../sections/users/cards/calls-list";
import ButtonNeoGen from "../../layout/button-neogen";
import { useBreakpoint } from "../../hooks/appMedia";
import { BuildingOffice2Icon, InformationCircleIcon, UserIcon } from "@heroicons/react/24/outline";
import OptionsDropDown from "../../layout/options-dropdown";

function Companies({ darkMode }: { darkMode?: boolean }) {
    const [search, setSearch] = useState("");
    const [debouncedSearch, setDebouncedSearch] = useState("");
    const [showNewCompanyModal, setShowNewCompanyModal] = useState(false);
    const authToken = getAuthTokenNoThrow() || "no-auth";
    const navigate = useNavigate();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const updateDebouncedSearch = useCallback(
        debounce(
            (term) => {
                setDebouncedSearch(term);
            },
            700,
            { trailing: true, maxWait: 1000 },
        ),
        [],
    );
    useEffect(() => {
        updateDebouncedSearch(search);
    }, [search, updateDebouncedSearch]);

    const companiesQuery = useCompanies({ authToken });
    const companies = useMemo(() => companiesQuery.data || [], [companiesQuery.data]);
    const filteredCompanies = useMemo(
        () =>
            debouncedSearch
                ? (ssSearch(
                      companies,
                      [
                          "name",
                          "email",
                          "phone",
                          "address",
                          "website",
                          "ownedBy.firstName",
                          "ownedBy.lastName",
                          "ownedBy.email",
                          "ownedBy.phone",
                      ],
                      debouncedSearch,
                  ) as Company[])
                : companies,
        [companies, debouncedSearch],
    );

    const usersQuery = useUsers({ authToken });
    const users = useMemo(() => usersQuery.data || [], [usersQuery.data]);
    const [searchOpen, setSearchOpen] = useState(false);
    const roleGroupsQuery = useRoleGroups({ authToken });
    const roleGroups = useMemo(() => roleGroupsQuery.data || [], [roleGroupsQuery.data]);
    const breakpoints = useBreakpoint();
    const isMobile = breakpoints.breakpoint === "mobile";
    const isTablet = breakpoints.breakpoint === "tablet";

    return (
        <>
            {showNewCompanyModal && (
                <NewCompanyModal
                    onClose={() => setShowNewCompanyModal(false)}
                    onCompanyCreated={({ company }) => {
                        companiesQuery.refetch();
                        navigate(`/companies/${company.id}`);
                    }}
                    affiliates={users}
                    roleGroups={roleGroups}
                />
            )}
            <PageDescription
                title="Companies"
                description2="This is a list of all companies. You can add, edit and delete companies."
                buttons={[
                    {
                        label: "New Company",
                        icon: "fas fa-plus",
                        onClick: () => {
                            setShowNewCompanyModal(true);
                        },
                    },
                    {
                        label: "Search",
                        icon: "fas fa-search",
                        type: "secondary",
                        onClick: () => {
                            setSearchOpen(true);
                        },
                    },
                ]}
            >
                <UserCompanyPicker
                    companyList={companiesQuery.data}
                    setCompany={(company: Company) => {
                        navigate(`/companies/${company.id}`);
                    }}
                    open={searchOpen}
                    setOpen={setSearchOpen}
                />
            </PageDescription>
            <div>
                {companiesQuery.isLoading ? (
                    <div className={"p-5 text-center"}>
                        <Loader2 />
                    </div>
                ) : !isMobile && !isTablet ? (
                    <>
                        <Table>
                            <THead>
                                <Tr>
                                    <Th>Name</Th>
                                    <Th>Created</Th>
                                    <Th>Status</Th>
                                    <Th>Owner</Th>
                                    <Th>Value</Th>
                                    <Th />
                                </Tr>
                            </THead>
                            <TBody>
                                {filteredCompanies.map((company) => {
                                    return (
                                        <Tr key={company.id}>
                                            <Td>{company.name || "-"}</Td>
                                            <Td style={{ paddingTop: 0, paddingBottom: 0 }}>
                                                <div className="flex flex-col">
                                                    {company.createdAt?.toDateString()}
                                                    <span style={{ color: "gray" }}>
                                                        {company.createdAt
                                                            ? timeAgo(company.createdAt).toLocaleString()
                                                            : ""}
                                                    </span>
                                                </div>
                                            </Td>
                                            <Td style={{ paddingTop: 0, paddingBottom: 0 }}>
                                                <div className="flex flex-col">
                                                    <div>{company.status ? startCase(company.status) : "-"}</div>
                                                    <div style={{ color: "gray" }}>
                                                        {company.statusUpdatedAt
                                                            ? timeAgo(company.statusUpdatedAt).toLocaleString()
                                                            : null}
                                                    </div>
                                                </div>
                                            </Td>
                                            <Td>{[company.ownedBy?.firstName, company.ownedBy?.lastName].join(" ")}</Td>
                                            <Td>{company.ownedBy?.firstName}</Td>
                                            <Td style={{ paddingTop: 0, paddingBottom: 0 }}>
                                                <ButtonNeoGen
                                                    size="sm"
                                                    onClick={() => {
                                                        navigate(`/companies/${company.id}`);
                                                    }}
                                                >
                                                    Open company
                                                </ButtonNeoGen>
                                            </Td>
                                        </Tr>
                                    );
                                })}
                            </TBody>
                        </Table>
                    </>
                ) : (
                    <ul>
                        {filteredCompanies.map((company) => (
                            <li
                                key={company.id}
                                className="bg-gray-50 dark:bg-gray-700 dark:border-gray-900 p-4 rounded-lg shadow-md mb-4"
                            >
                                <div className="flex items-start justify-between">
                                    <div>
                                        <div className="flex gap-2 pb-1">
                                            <BuildingOffice2Icon
                                                color={darkMode ? "#4ade80" : "#4338ca"}
                                                className="w-5 h-5"
                                            />
                                            <span>{company.name ? company.name : "Unknown"}</span>
                                        </div>
                                        <div className="flex gap-2 pb-1">
                                            <UserIcon color={darkMode ? "#4ade80" : "#4338ca"} className="w-5 h-5" />
                                            <span>
                                                {company.ownedBy?.firstName
                                                    ? [company.ownedBy?.firstName, company.ownedBy?.lastName].join(" ")
                                                    : "Unknown"}
                                            </span>
                                        </div>
                                        <div className="flex gap-2 pb-1">
                                            <InformationCircleIcon
                                                color={darkMode ? "#4ade80" : "#4338ca"}
                                                className="w-5 h-5"
                                            />
                                            <span>{company.status ? startCase(company.status) : "Unknown"}</span>
                                        </div>
                                    </div>
                                    <OptionsDropDown
                                        asEllipses
                                        options={[
                                            {
                                                label: "Open company",
                                                action: () => navigate(`/companies/${company.id}`),
                                                icon: "fa fa-pencil",
                                            },
                                        ]}
                                    />
                                </div>
                                <div className="flex justify-start w-full mt-2">
                                    <div className="text-xs font-light italic">
                                        Created at:{" "}
                                        <span>
                                            {company.createdAt
                                                ? timeAgo(company.createdAt).toLocaleString()
                                                : "Unknown"}
                                        </span>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </>
    );
}

export default Companies;
