import { z } from "zod";
import ModalDialog from "../../../layout/modal-dialog";
import { useForm } from "../../../hooks/useForm";
import { Form } from "../../../layout/form/form";
import { getAuthTokenNoThrow } from "../../../services/auth-header";
import ButtonNeoGen from "../../../layout/button-neogen";
import { Company } from "../../domain/company";
import { NumberField } from "../../../layout/form/number-field";
import { TextAreaField } from "../../../layout/form/text-area-field";
import { User } from "../../../jason-proof-of-concept/users/domain/user";
import { Field } from "../../../layout/form/field";
import { useCreatePaymentMutation } from "../../../payments/hooks/use-create-payment";
import { DateTimeField } from "../../../layout/form/date-field";
import { useAuth } from "../../../auth/use-auth";
import { Payment } from "../../../payments/domain/payment";
import { TextField } from "../../../layout/form/text-field";
import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import {
    CreatePaymentRequestData,
    createPaymentRequest,
} from "../../../payment-requests/actions/create-payment-request";
import { PaymentRequest, PaymentRequestStatus } from "../../../payment-requests/domain/payment-request";
import { SelectField } from "../../../layout/form/select-field";

export const CreatePaymentRequestModal = ({
    onClose,
    company,
    defaultAmount,
    toUser: toUser,
    onCreate: onCreate,
    description,
    maxAmount,
}: {
    onClose: () => any;
    company: Company;
    defaultAmount?: number;
    toUser?: User;
    onCreate: (paymentRequest: PaymentRequest) => void;
    description?: string;
    maxAmount?: number;
}) => {
    const schema = z.object({
        description: z.string(),
        requestedPaymentDate: z.date(),
        amount: maxAmount ? z.number().positive().max(maxAmount) : z.number().positive(),
        notes: z.string().optional(),
    });

    type Data = z.infer<typeof schema>;

    const auth = useAuth();
    const authedUser = auth.expectUser();
    const authToken = getAuthTokenNoThrow() || "no-auth-token";

    const form = useForm({
        schema,
        defaultValues: {
            amount: defaultAmount,
            description,
            requestedPaymentDate: new Date(),
            notes: "",
        },
    });

    const createPaymentRequestMutation = useMutation({
        mutationFn: async (data: CreatePaymentRequestData) => {
            const paymentRequest = await createPaymentRequest({ authToken, data });
            return paymentRequest;
        },
    });

    const handleSubmit = async (data: Data) => {
        if (!toUser) {
            throw new Error("User is required");
        }
        const paymentRequest = await createPaymentRequestMutation.mutateAsync({
            companyId: company.id,
            amount: data.amount,
            fromClearErc: true,
            toUserId: toUser.id,
            paymentDate: data.requestedPaymentDate,
            description: data.description,
            status: PaymentRequestStatus.Pending,
        });
        onCreate(paymentRequest);
    };

    return (
        <>
            <ModalDialog show title={"New payment request"} close={onClose} showOk={false} showCancel={false} size="sm">
                <p className="text-center mb-6 text-gray-400">
                    Create a request for a payment to be made between two parties.
                </p>
                <Form
                    onSubmit={form.handleSubmit(handleSubmit as any)}
                    error={createPaymentRequestMutation.error as any}
                >
                    <div className="flex flex-row">
                        <div className="flex-1">
                            <Field label="From">ClearERC</Field>
                        </div>
                        <div className="flex-1">
                            <Field label="To">
                                <div>
                                    {toUser?.firstName} {toUser?.lastName}
                                </div>
                            </Field>
                        </div>
                    </div>
                    <TextField label="Description" {...form.getFieldProps("description")} />
                    <DateTimeField
                        label="Requested payment date"
                        dateFormat="yyyy/MM/dd"
                        showTimeInput={false}
                        showTimeSelect={false}
                        {...form.getFieldProps("requestedPaymentDate")}
                    />
                    <NumberField label="Amount" {...form.getFieldProps("amount")} />
                    <TextAreaField label="Notes" {...form.getFieldProps("notes")} />
                    <div className="flex justify-end gap-4">
                        <ButtonNeoGen
                            type="outline"
                            disabled={createPaymentRequestMutation.isLoading}
                            onClick={() => onClose()}
                        >
                            Cancel
                        </ButtonNeoGen>
                        <ButtonNeoGen type="submit" disabled={createPaymentRequestMutation.isLoading}>
                            Create payment request
                        </ButtonNeoGen>
                    </div>
                </Form>
            </ModalDialog>
        </>
    );
};
